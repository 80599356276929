<template>
  <div>
    <Modal
      v-model="costModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >费用调整</p>
      <div>
        <div class="p-t-10 workplatform-title">{{ curTaskInfo.costTypeName }}<span>调整</span></div>
        <Row :gutter="8">
          <i-col span="4"><span class="title">计费单位</span>
            <Select
              v-model="costModel.chargeType"
              size="small"
            >
              <Option
                v-for="(unit, index) in unitArray"
                :key="index"
                :value="unit.id"
              >{{ unit.name }}</Option>
            </Select>

          </i-col>
          <i-col span="4"><span class="title">计费数量</span>
            <InputNumber
              v-model="costModel.chargeQuantity"
              style="width:100%"
              size="small"
              placeholder="请填写调整项金额"
            >
            </InputNumber>
          </i-col>
          <i-col span="4"><span class="title">计费单价(¥)</span>
            <InputNumber
              v-model="costModel.unitCost"
              style="width:100%"
              size="small"
              placeholder="请填写调整项金额"
            >
            </InputNumber>
          </i-col>
          <i-col span="9"><span class="title">备注</span>
            <Input
              v-model="costModel.remark"
              style="width:100%"
              size="small"
              placeholder="请填写备注"
            />
          </i-col>
          <i-col span="3">
            <span class="title">操作</span>
            <Button
              type="success"
              size="small"
              v-if="isAuth('inspection_index_fee')"
              @click="confirmUpdate"
            >确认调整</Button>
          </i-col>
        </Row>
        <div>
          <div class="p-t-10 workplatform-title">计费项变更历史</div>
          <Table
            stripe
            size="small"
            :data="historylist"
            :columns="historyColumn"
            :loading="dataLoading"
          ></Table>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          @click="costModal = false"
        >取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getChargeModifyList, modifycharge } from '@/api/msp/settlement'
export default {
  data () {
    return {
      costModal: false,
      costModel: {
        chargeQuantity: 1,
        chargeType: 1,
        taskitemChargeId: null,
        unitCost: 0,
        remark: ''
      },
      curTaskInfo: {},
      unitArray: [
        { id: 0, name: '次' },
        { id: 1, name: '块' },
        { id: 2, name: '面积(㎡)' },
        { id: 4, name: '次/工' },
        { id: 5, name: '个' },
        { id: 6, name: '站/次' }
      ],
      historylist: [],
      dataLoading: false,
      historyColumn: [
        {
          title: '计费数量',
          key: 'chargeQuantity',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeQuantity + '' + params.row.chargeQuantityUnit)])
          }
        },

        {
          title: '计费单价',
          key: 'chargeUnitCost',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.chargeUnitCost) + '/' + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '计费总价',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.cost))])
          }
        },
        { title: '变更时间', width: 160, key: 'createTime' },
        { title: '备注', ellipsis: true, tooltip: true, key: 'remark' },
        { title: '变更操作人', key: 'userName' }
      ]
    }
  },
  methods: {
    showModal (data = null) {
      if (data) {
        this.curTaskInfo = data

        this.costModel.chargeQuantity = data.confirmChargeQuantity
        this.costModel.chargeType = data.confirmChargeType
        this.costModel.taskitemChargeId = data.id
        this.costModel.unitCost = data.confirmChargeUnitCost
        this.costModel.remark = data.remark || ''

        this.getHistoryData()
        this.costModal = true
      } else {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
      }
    },
    async getHistoryData () {
      this.dataLoading = true
      const result = await getChargeModifyList({ taskitemChargeId: this.costModel.taskitemChargeId }).finally(() => { this.dataLoading = false })
      this.historylist = result && !result.errcode ? result : []
    },
    confirmUpdate () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          const result = await modifycharge(this.costModel)
          if (result && result.errcode === 0) {
            this.costModal = false
            // this.getHistoryData()
            this.$emit('updateTable')
            this.$Notice.success({ desc: '操作成功' })
          }
        }
      })
    }
  }
}
</script>
